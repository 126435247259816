import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { HOMEPAGE, TERMS } from "./route-codes";

const Homepage = lazy(() => import("components/Homepage/Homepage"));
const Terms = lazy(() => import("components/Terms/Terms"));

const CustomRoutes = () => {
  return (
    <Suspense fallback={<div></div>}>
      <BrowserRouter>
        <Routes>
          {/* <Route exact path="/" element={<Navigate to={HOMEPAGE} />} /> */}
          <Route exact path={`${HOMEPAGE}`} element={<Homepage />} />
          <Route exact path={`${TERMS}`} element={<Terms />} />

          <Route exact path="*" element={<Homepage />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default CustomRoutes;
